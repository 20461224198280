<template>
  <form>
    <v-container>
      <Ausfahrtmelden
        :active="dialog.ausfahrtmelden"
        :datum="date"
        :doc="doc"
      ></Ausfahrtmelden>
      <v-card v-if="$store.state.auth.isAuthenticating">
        <v-banner v-if="$store.state.busexit.isOnline == false" single-line>
          <v-icon slot="icon" color="warning" size="36">
            mdi-wifi-strength-alert-outline
          </v-icon>
          Offline-Modus!
        </v-banner>
        <v-card-title>
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Ausfahrt
        </v-card-title>
        <v-card-subtitle>
          letzte Aktualisierung:
          {{ $store.state.busexit.lastChange }}
        </v-card-subtitle>
        <v-card-text> </v-card-text>
        <v-card-actions v-if="monitor == false">
          <v-btn text color="primary" @click.native="getAusfahrt(false)">
            <v-icon>mdi-arrow-left</v-icon>
            zurück
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="exitdate"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :max="aktdate"
              :locale="'de'"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="getAusfahrt(true)">
            vor
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-card>
              <v-card-title>
                <v-icon>mdi-bus-clock</v-icon>
                &nbsp; Überfällig
              </v-card-title>
            </v-card>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card id="overdue-busexit">
              <v-card-text>
                <v-data-table
                  :headers="busexitheaders"
                  fixed-header
                  :items="this.overduedata"
                  :item-class="itemRowColor"
                  :items-per-page="500"
                  @click:row="clickAusfahrtmelden"
                  :mobile-breakpoint="550"
                  item-key="id"
                  :loading="isLoading"
                  :class="scrollbarTheme"
                  hide-default-footer
                  height="28em"
                >
                  <template v-slot:[`item.fahrer`]="{ item }">
                    {{ item.neuFahrer ? item.neuFahrer : item.fahrer }}
                  </template>
                  <template v-slot:[`item.strang`]="{ item }">
                    {{ getBusLocation(item.strang) }}
                  </template>
                  <template v-slot:[`item.notes`]="{ item }">
                    <span v-if="item.kurzdienst">*</span>
                    <span v-else-if="item.sbs">#</span>
                    <span v-else></span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-card>
              <v-card-title>
                <v-icon>mdi-bus-clock</v-icon>
                &nbsp; Ausfahrt
              </v-card-title>
            </v-card>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card id="exit-busexit">
              <v-card-text>
                <template>
                  <v-data-table
                    :headers="busexitheaders"
                    fixed-header
                    :items="this.exitdata"
                    :item-class="itemRowColor"
                    :items-per-page="1000"
                    @click:row="clickAusfahrtmelden"
                    :mobile-breakpoint="550"
                    item-key="id"
                    :loading="isLoading"
                    :class="scrollbarTheme"
                    hide-default-footer
                    height="28em"
                  >
                    <template v-slot:[`item.fahrer`]="{ item }">
                      {{ item.neuFahrer ? item.neuFahrer : item.fahrer }}
                    </template>
                    <template v-slot:[`item.strang`]="{ item }">
                      {{ getBusLocation(item.strang) }}
                    </template>
                    <template v-slot:[`item.notes`]="{ item }">
                      <span v-if="item.kurzdienst">*</span>
                      <span v-else-if="item.sbs">#</span>
                      <span v-else></span>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-card>
              <v-card-title>
                <v-icon>mdi-bus-clock</v-icon>
                &nbsp; Erledigt
              </v-card-title>
            </v-card>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card id="done-busexit">
              <v-card-text>
                <template>
                  <v-data-table
                    :headers="busexitheaders"
                    fixed-header
                    :items="this.donedata"
                    :item-class="itemRowColor"
                    :items-per-page="1000"
                    :mobile-breakpoint="550"
                    item-key="id"
                    :loading="isLoading"
                    :class="scrollbarTheme"
                    hide-default-footer
                    height="28em"
                  >
                    <template v-slot:[`item.fahrer`]="{ item }">
                      {{ item.neuFahrer ? item.neuFahrer : item.fahrer }}
                    </template>
                    <template v-slot:[`item.strang`]="{ item }">
                      {{ getBusLocation(item.strang) }}
                    </template>
                    <template v-slot:[`item.notes`]="{ item }">
                      <span v-if="item.kurzdienst">*</span>
                      <span v-else-if="item.sbs">#</span>
                      <span v-else></span>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-card>
              <v-card-title>
                <v-icon>mdi-bus-clock</v-icon>
                &nbsp; nicht besetzte Dienste
              </v-card-title>
            </v-card>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <v-data-table
                  :headers="busexitheaders"
                  fixed-header
                  :items="this.unmanneddata"
                  :item-class="itemRowColor"
                  :items-per-page="500"
                  item-key="id"
                  :loading="isLoading"
                  :class="scrollbarTheme"
                  hide-default-footer
                  height="28em"
                >
                  <template v-slot:[`item.fahrer`]="{ item }">
                    {{ item.neuFahrer ? item.neuFahrer : item.fahrer }}
                  </template>
                  <template v-slot:[`item.strang`]="{ item }">
                    {{ getBusLocation(item.strang) }}
                  </template>
                  <template v-slot:[`item.notes`]="{ item }">
                    <span v-if="item.kurzdienst">*</span>
                    <span v-else-if="item.sbs">#</span>
                    <span v-else></span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
<!--
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-card>
              <v-card-title>
                <v-icon>mdi-bus-clock</v-icon>
                &nbsp; Rangierer
              </v-card-title>
            </v-card>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <v-data-table
                  id="virtual-scroll-table"
                  :class="scrollbarTheme"
                  :headers="busexitheaders"
                  :items="rangiererdata"
                  item-key="id"
                  @click:row="clickAusfahrtmelden"
                  :mobile-breakpoint="550"
                  :loading="isLoading"
                  disable-pagination
                  hide-default-footer
                  fixed-header
                  height="350"
                >
                  <template v-slot:[`item.fahrer`]="{ item }">
                    {{ item.neuFahrer ? item.neuFahrer : item.fahrer }}
                  </template>
                  <template v-slot:[`item.strang`]="{ item }">
                    {{ getBusLocation(item.strang) }}
                  </template>
                  <template v-slot:[`item.notes`]="{ item }">
                    <span v-if="item.kurzdienst">*</span>
                    <span v-else-if="item.sbs">#</span>
                    <span v-else></span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>-->
      </v-expansion-panels>
    </v-container>
  </form>
</template>

<script>
import { DateTime } from "luxon";

import Ausfahrtmelden from "@/components/dialog/ausfahrtmelden"

export default {
  data() {
    return {
      busexitheaders: [
        {
          text: "",
          align: "start",
          value: "notes",
        },
        {
          text: "Dienst",
          value: "dienst",
        },
        { text: "Betrieb", value: "info" },
        { text: "Meldezeit", value: "meldezeit" },
        { text: "Anwesendzeit", value: "anwesendzeit" },
        { text: "Ausfahrtszeit", value: "anfang" },
        { text: "Linie", value: "linie" },
        { text: "Bus", value: "fahrzeug" },
        { text: "Wagen Nr", value: "wagennr" },
        { text: "Fahrerkarte", value: "drivernumber" },
        { text: "Strang", value: "strang" },
        { text: "Einfahrt", value: "ende" },
        { text: "Hinweis", value: "hinweis" },
        { text: "Fahrer/in", value: "fahrer" },
      ],
      dialog: {
        ausfahrtmelden: 0,
      },
      monitor: false,
      exitdate: null,
      anzahlbus: {},
      fiveMinutesAfter: null,
      fifteenMinutesAfter: null,
      akthour: null,
      exitdata: [],
      overduedata: [],
      donedata: [],
      unmanneddata: [],
      reservedata: [],
      rangiererdata: [],
      specialServices: ["RAN", "RES"],
      timerInterval: null,
      date: null,
      aktdate: DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"),
      panel: [],
      panelValue: [
        { value: "overdue", position: 0 },
        { value: "exit", position: 1 },
        { value: "done", position: 2 },
        { value: "reserve", position: 3 },
        { value: "rangierer", position: 4 },
      ],
    };
  },

  watch: {
    date: {
      handler(newValue, oldValue) {
        if (newValue > DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")) {
          newValue = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
          this.date = newValue;
        }
        this.exitdate = DateTime.fromFormat(newValue, "yyyy-MM-dd")
          .setLocale("de")
          .toFormat("dd.MM.yyyy");
        clearInterval(this.timerInterval);
        this.$store.dispatch("detachBusexit").then(() => {
          this.$store.dispatch("attachBusExit", { date: newValue })
            .then(() => {
              if (newValue === DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")) {
                console.log("Datumwatch 1: ", newValue, oldValue);
                this.fetchData();
                this.timerInterval = setInterval(this.fetchData, 1000);
              } else {
                console.log("Datumwatch 2: ", newValue, oldValue);
                this.fetcholdData();
              }
            });
        });
      },
      deep: true,
    },
    //   "$store.state.auth.isAuthenticating": {
    //     handler: function (isAuthenticating) {
    //       if (isAuthenticating) {
    //         this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    //         this.$store
    //           .dispatch("attachBusExitOnSnapshotoffline", { date: this.date })
    //           .then(() => {
    //             this.timerInterval = setInterval(this.fetchData, 60000);
    //           });
    //       }
    //     },
    //     immediate: true,
    //   },
  },

  computed: {
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "busexit-dark dark" : "busexit-light light";
    },
  },

  methods: {
    fetchData() {
      this.anzahlbus = this.$store.getters.getBusAnzahl(this.$store.state.busexit.busexitdata)
//      console.log("FETCHDATA", this.anzahlbus)
      this.fiveMinutesAfter = DateTime.now()
        .plus({ minutes: 5 })
        .setLocale("de")
        .toFormat("HH:mm")
      this.fifteenMinutesAfter = DateTime.now()
        .plus({ minutes: -10 })
        .setLocale("de")
        .toFormat("HH:mm");
      this.akthour = DateTime.now().setLocale("de").toFormat("HH:mm");
      this.overduedata = this.$store.getters.getOverdueData(this.fiveMinutesAfter, this.akthour, 
              this.specialServices, this.$store.state.busexit.busexitdata)
      this.exitdata = this.$store.getters.getExitData(this.fifteenMinutesAfter, this.specialServices, 
              this.$store.state.busexit.busexitdata)
      this.donedata = this.$store.getters.getDoneData(this.specialServices, this.$store.state.busexit.busexitdata)
      this.unmanneddata = this.$store.getters.getUnmannedData(this.fiveMinutesAfter, this.akthour, 
              this.specialServices, this.$store.state.busexit.busexitdata)
    },

    fetcholdData() {
      this.fiveMinutesAfter = "23:59"
      this.fifteenMinutesAfter = "23:59"
      this.akthour = DateTime.now().setLocale("de").toFormat("HH:mm");
      this.overduedata = this.$store.getters.getOverdueData(this.fiveMinutesAfter, this.fiveMinutesAfter, 
              this.specialServices, this.$store.state.busexit.busexitdata)
      this.exitdata = this.$store.getters.getExitData(this.fifteenMinutesAfter, this.specialServices, 
              this.$store.state.busexit.busexitdata)
      this.donedata = this.$store.getters.getDoneData(this.specialServices, this.$store.state.busexit.busexitdata)
      this.unmanneddata = this.$store.getters.getUnmannedData(this.fiveMinutesAfter, this.akthour, 
              this.specialServices, this.$store.state.busexit.busexitdata)
    },

    getReserveData() {
      this.reservedata = this.$store.state.busexit.data.filter(
        (data) => data.linie == "RES"
      );
    },

    getRangiererData() {
      this.rangiererdata = this.$store.state.busexit.data.filter((data) => {
        return data.linie == "RAN";
      });
    },

    /*getOverdueData(akt) {
      this.overduedata = this.$store.state.busexit.data.filter((data) => {
        let fiveMinutesAfter = DateTime.now()
          .plus({ minutes: 5 })
          .setLocale("de")
          .toFormat("HH:mm");
        let akthour = DateTime.now().setLocale("de").toFormat("HH:mm");
        if (akt == false) {
          fiveMinutesAfter = "23:59";
        }
        //console.log("Overduedata: ",akt, fiveMinutesAfter);
        return (
          data.anfang <= fiveMinutesAfter &&
          data.meldezeit <= akthour &&
          !data.gemeldet &&
          !this.specialServices.includes(data.linie)
        );
      });
    },*/

    /*getExitData(akt) {
      this.exitdata = this.$store.state.busexit.data.filter((data) => {
        let fiveMinutesAfter = DateTime.now()
          .plus({ minutes: 5 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (akt == false) {
          fiveMinutesAfter = "23:59";
        }
        //console.log("Exitdata: ",akt, fiveMinutesAfter);
        return (
          data.anfang > fiveMinutesAfter &&
          !data.gemeldet &&
          !this.specialServices.includes(data.linie)
        );
      });
    },*/

    /*getDoneData() {
      this.donedata = this.$store.state.busexit.data.filter((data) => {
        return (
          data.gemeldet &&
          data.gemeldet_um &&
          !this.specialServices.includes(data.linie)
        );
      });
    },*/

    getBusLocation(strang) {
      if (isNaN(strang)) {
        return `${strang.strangNummer}-${strang.strangPosition}`;
      } else {
        return "";
      }
    },

    itemRowColor(item) {
      if (item.gemeldet == true) {
        return "style-6";
      }
      if (this.date === DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")) {
        let zeit = DateTime.now().plus({}).setLocale("de").toFormat("HH:mm");
        if (item.meldezeit < zeit) {
          if (item.anfang <= zeit) {
            let zeitaus = DateTime.now()
              .minus({ minutes: 5 })
              .setLocale("de")
              .toFormat("HH:mm");
//              console.log("ITEM-Color", item.anfang, zeit, zeitaus)
              if (item.anfang >= zeitaus) {  
                item.class = item.class == 'style-5' ? 'style-5-1' : 'style-5'
              } else {
                item.class = 'style-5'
              }
            return item.class;
          }
          return "style-5";
        }
        zeit = DateTime.now()
          .plus({ minutes: +2 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (item.meldezeit <= zeit) {
          return "style-4";
        }
        zeit = DateTime.now()
          .plus({ minutes: +5 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (item.meldezeit <= zeit) {
          return "style-3";
        }
        zeit = DateTime.now()
          .plus({ minutes: +10 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (item.meldezeit <= zeit) {
          return "style-2";
        }
        zeit = DateTime.now()
          .plus({ minutes: +75 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (item.meldezeit <= zeit) {
          return "style-1";
        }
        //https://stackoverflow.com/questions/57961043/how-does-one-style-a-specific-row-on-v-data-table-vuetify
        return "style-0";
      } else {
        return "style-5"
      }
    },

    clickAusfahrtmelden(value) {
      this.doc = value;
      this.dialog.ausfahrtmelden++;
    },

    getAusfahrt(_day) {
      if (_day) {
        this.date = DateTime.fromFormat(this.date, "yyyy-MM-dd")
          .setLocale("de")
          .plus({ days: +1 })
          .toFormat("yyyy-MM-dd");
      } else {
        this.date = DateTime.fromFormat(this.date, "yyyy-MM-dd")
          .setLocale("de")
          .minus({ days: 1 })
          .toFormat("yyyy-MM-dd");
      }
    },
  },

  created() {
    //this.$store.dispatch("attachStrangOnSnapshotoffline")
    this.$store.dispatch("attachbusexittypes")
  },

  mounted() {
    //console.log(this.$route.query);
    if (typeof this.$route.query.monitor !== "undefined") {
      this.monitor = this.$route.query.monitor;
    } else {
      this.monitor = false;
    }
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.exitdate = DateTime.fromFormat(this.date, "yyyy-MM-dd")
      .setLocale("de")
      .toFormat("dd.MM.yyyy");
    this.$store.dispatch("attachBusExit", { date: this.date }).then(() => {
      this.fetchData();
      this.timerInterval = setInterval(this.fetchData, 1000);
    });
    //this.fetchData();
    //this.timerInterval = setInterval(this.fetchData, 1000);
    const params = Object.keys(this.$route.query);
    const panel = [];
    params.forEach((param) => {
      this.panelValue.forEach((val) => {
        if (val.value == param) {
          panel.push(val.position);
        }
      });
    });
    this.panel = panel;
  },

  beforeDestroy() {
    clearInterval(this.timerInterval);
    this.$store.dispatch("detachBusexit");
    this.$store.dispatch("detachhbusexittypes");
  },

  components: {
    Ausfahrtmelden,
  },
};
</script>

<style>
.container {
  max-width: 98% !important;
}
/*
.style-1 {
  color: rgb(240, 240, 125);
}
.style-2 {
  color: rgb(236, 236, 11);
}*/
.style-1 {
  color: rgb(93, 93, 19);
}
.style-2 {
  color: rgb(148, 173, 7);/*(162, 144, 6);/*240, 240, 125*/
}
.style-3 {
  color: rgb(235, 118, 10);
}
.style-4 {
  color: rgb(226, 70, 49);
}
.style-5 {
  color: rgb(185, 7, 7);
}
.style-5-1 {
  color: rgb(235, 9, 223);
}
.style-6 {
  color: rgb(14, 212, 30);
}

#overdue-busexit {
  height: 28em;
}
#exit-busexit {
  height: 28em;
}
#done-busexit {
  height: 28em;
}

.busexit-dark .v-data-table__wrapper::-webkit-scrollbar {
  width: 25px;
  height: 25px;
}
.busexit-dark .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.busexit-dark .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.busexit-dark .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: white;
}

.busexit-light .v-data-table__wrapper::-webkit-scrollbar {
  width: 25px;
  height: 25px;
}

.busexit-light .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.busexit-light .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.busexit-light .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: black;
}

</style>
