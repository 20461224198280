<template>
  <v-app>
    <v-snackbar
      v-model="$store.state.snackbar.snackbar"
      :color="$store.state.snackbar.snackbarColor"
    >
      {{ $store.state.snackbar.snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="$store.state.snackbar.snackbar = false"> Schließen </v-btn>
      </template>
    </v-snackbar>

    <Navigation></Navigation>
    <v-container fill-height fluid style="height: 100%" v-if="$store.state.auth.isLoading">
      <v-row class="text-center">
        <v-col cols="12">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-main
      v-if="
        $store.state.auth.isAuthenticating &&
        $store.getters.hasPermission(this.$route)
      "
    >
      <router-view></router-view>
    </v-main>
    <v-main v-else>
    </v-main>
  </v-app>
</template>

<script>
import { DateTime } from "luxon";
import Navigation from "@/components/app/Navigation";

export default {
  name: "App",
  data() {
    return {};
  },

  mounted() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")
    //this.$store.dispatch("attachBusExit", { date: this.date })
  },
  created() {
    this.$store.dispatch("attachSettingsOnSnapshotoffline")
    this.$store.dispatch("attachStrangOnSnapshotoffline")
    /*  if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }*/
  },
  computed: {},
  components: {
    Navigation,
  },
  beforeDestroy() {
    //this.$store.dispatch("detachBusexit")
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
.v-card p {
  margin: 0;
}
</style>
