import busexitview from '@/views/busexit/busexitview.vue'
import busentranceview from '@/views/busexit/busentranceview.vue'
import delayview from '@/views/busexit/delayview.vue'
import strangverwaltung from '@/views/busexit/strangverwaltung.vue'
import manuelleausfahrt from '@/views/busexit/manuelleausfahrt.vue'
import platzverwaltung from '@/views/busexit/platzverwaltung.vue'
import platzverwaltungv2 from '@/views/busexit/platzverwaltungv2.vue'
import busRangiererMenue from '@/views/busexit/busRangiererMenue.vue'
import busrangiererexitview from '@/views/busexit/busrangiererexitview.vue'
import busexitlistview from '@/views/busexit/busexitlistview.vue'
import zblbusexitview from '@/views/busexit/zblbusexitview.vue'
import vehicletrapezedataview from '@/views/busexit/vehicletrapezeview.vue'
import rangiererstrangverwaltung from '@/views/busexit/rangiererstrangverwaltung.vue'
import busparkingspace from '@/views/busexit/busparkingspace.vue'

const routesbusexit = [
    {
        path: '/busexitview',
        name: 'Ausfahrtsübersicht',
        icon: 'mdi-bus-clock',
        right: 'busexitview',
        navParent: "Ausfahrt",
        component: busexitview
    },
    {
        path: '/busentranceview',
        name: 'Einfahrtsübersicht',
        icon: 'mdi-bus-clock',
        right: 'busexitview',
        navParent: "Ausfahrt",
        component: busentranceview
    },
    {
        path: '/zblbusexitview',
        name: 'ZBL-Ausfahrtsübersicht',
        icon: 'mdi-bus-clock',
        right: 'DSB-Co6ejP0fsu-bnCN8m1MVY',
        navParent: "Ausfahrt",
        component: zblbusexitview
    },
    {
        path: '/rangiererstrangverwaltung',
        name: 'Rangierer Strangverwaltung',
        icon: 'mdi-land-rows-vertical',
        right: 'rangiererstrangverwaltung',
        navParent: "Ausfahrt",
        component: rangiererstrangverwaltung
    },
    {
        path: '/busrangiererexitview',
        name: 'Rangierer Ausf. Übersicht',
        icon: 'mdi-bus-clock',
        right: 'busrangiererexitview',
        navParent: "Ausfahrt",
        component: busrangiererexitview
    },
    {
        path: '/manuelleausfahrt',
        name: 'Manuelle Ausfahrt',
        icon: 'mdi-location-exit',
        right: 'manuelleausfahrt',
        navParent: "Ausfahrt",
        component: manuelleausfahrt
    },
    /*{
        path: '/busparkingspace',
        name: 'Stellplatzübersicht',
        icon: 'mdi-location-exit',
        right: 'strangverwaltung',
        navParent: "Ausfahrt",
        component: busparkingspace
    },*/
    {
        path: '/delayview',
        name: 'Verspätungen',
        icon: 'mdi-timer-alert',
        right: 'verspätungen',
        navParent: "Ausfahrt",
        component: delayview
    },
    {
        path: '/strangverwaltung',
        name: 'Strangverwaltung',
        icon: 'mdi-land-rows-vertical',
        right: 'strangverwaltung',
        navParent: "Ausfahrt",
        component: strangverwaltung
    },
    /*{
        path: '/platzverwaltung',
        name: 'Platzverwaltung',
        icon: 'mdi-bus-marker',
        right: 'platzverwaltung',
        navParent: "Ausfahrt",
        component: platzverwaltung
    },
    {
        path: '/platzverwaltungv2',
        name: 'Platzverwaltung V2',
        icon: 'mdi-bus-marker',
        right: 'platzverwaltungv2',
        navParent: "Ausfahrt",
        component: platzverwaltungv2
    },*/
    {
        path: '/busrangierermenue',
        name: 'Busrangierer Menü',
        icon: 'mdi-bus-marker',
        right: 'busRangiererMenue',
        navParent: "Ausfahrt",
        component: busRangiererMenue
    },
    {
        path: '/busexitlistview',
        name: 'Meldeliste',
        icon: 'mdi-bus-clock',
        right: 'busexitlistview',
        navParent: "Ausfahrt",
        component: busexitlistview
    },
    {
        path: '/vehicletrapezedataview',
        name: 'Trapeze Daten',
        icon: 'mdi-bus-clock',
        right: 'vehicletrapezedataview',
        navParent: "Ausfahrt",
        component: vehicletrapezedataview
    },
];

export default routesbusexit;
