import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc, setDoc, deleteDoc, Timestamp } from "firebase/firestore";
const { DateTime } = require("luxon");
const timezone = 'Europe/Berlin';
/**
 * 
 * @returns 
 */
function getTimeStamp(date) {
    const now = new Date(date);
    const timestamp = Timestamp.fromDate(now);
    return timestamp;
}

const state = {
    strang: [],
    busseKategorie: [
        'E-Solobus BYD',
        'E-Solobus MB',
        'E-Gelenkbus',
        'Gelenkbus',
        'Solobus'
    ],
    firestoreListener: null,
    isLoading: false,
    isOnline: false,
    lastChange: null,
    busQuantity: 0,
    strangNamen: [],
    strangNamenFirestoreListener: null,
    strangNamenIsLoading: false
}

const getters = {
    sortStraenge: (state) => (type) => {
        if (type === 'ALL') {
            const time = DateTime.now().setZone(timezone).setLocale('de').toFormat("HH:mm");
            return state.strang.filter(data => data.nameobj.timefrom <= time && 
                data.nameobj.timeto >= time)
                .sort((a, b) => {
                    const nrA = parseInt(a.strangNummer)
                    const nrB = parseInt(b.strangNummer)
                    if (nrA < nrB) {
                        return -1
                    }
                    if (nrA > nrB) {
                        return 1
                    }
                    return 1
                })
        } else {
            return state.strang.filter(data => data.type == type)
                .sort((a, b) => {
                    const nrA = parseInt(a.strangNummer)
                    const nrB = parseInt(b.strangNummer)
                    if (nrA < nrB) {
                        return -1
                    }
                    if (nrA > nrB) {
                        return 1
                    }
                    return 0
                })
        }
    },

    getBusAnzahl:( state, rootGetters, rootState ) => ( busexitdata ) => {        
        let _anzahl = {}
        const akttime = DateTime.now().setLocale("de").toFormat("HH:mm")
        for (const _busexittypes of rootState.settings.busexittypes) {
            let _sl = 0
            let _gb = 0
            let _eslmb = 0
            let _eslbyd = 0
            let _egb = 0
            let _slsperre = 0
            let _gbsperre = 0
            let _eslbydsperre = 0
            let _eslmbsperre = 0
            let _egbsperre = 0
            for (const _strang of state.strang) {
                if (_strang.name === _busexittypes.text &&
                    _strang.istGesperrt === false &&
                    _strang.inVerteilung === true) {
                    switch(_strang.Kategorie) {
                        case "Solobus":
                            _sl += _strang.anzahl
                            break;
                        case "Gelenkbus":
                            _gb += _strang.anzahl
                            break;
                        case "E-Solobus BYD":
                            _eslbyd += _strang.anzahl
                            break;
                        case "E-Solobus Mercedes":
                            _eslmb += _strang.anzahl
                            break;
                        case "E-Gelenkbus":
                            _egb += _strang.anzahl
                            break;
                    }
                } else if (_strang.name === _busexittypes.text &&
                    _strang.istGesperrt === true &&
                    _strang.inVerteilung === true) {
                    switch(_strang.Kategorie) {
                        case "Solobus":
                            _slsperre += _strang.anzahl
                            break;
                        case "Gelenkbus":
                            _gbsperre += _strang.anzahl
                            break;
                        case "E-Solobus BYD":
                            _eslbydsperre += _strang.anzahl
                            break;
                        case "E-Solobus Mercedes":
                            _eslmbsperre += _strang.anzahl
                            break;
                        case "E-Gelenkbus":
                            _egbsperre += _strang.anzahl
                            break;
                    }
                }
            }
            let _slbenoetigt = 0
            let _gbbenoetigt = 0
            let _eslbydbenoetigt = 0
            let _eslmbbenoetigt = 0
            let _egbbenoetigt = 0
            for (const _busexit of busexitdata) {
                if (_busexittypes.text.toUpperCase() === "AUSFAHRT NACHTEXPRESS") {
                    if (!_busexit.hinweis.match(/NE[1-9]/g) === false || !_busexit.hinweis.match(/NE [1-9]/g) === false) {
                        if (_busexit.anfang <= _busexittypes.timeto &&
                            _busexit.anfang >= _busexittypes.timefrom && !_busexit.gemeldet) {
                            switch(_busexit.fahrzeug) {
                                case "SL":
                                case "SLz":
                                    if (_busexit.hinweis == 'E-Bus') {
                                        _eslmbbenoetigt += 1
                                    } else {
                                        _slbenoetigt += 1
                                    }
                                    break;
                                case "ESL":
                                    if (_busexit.hinweis == 'MB') {
                                        _eslmbbenoetigt += 1
                                    } else if (_busexit.hinweis == 'BYD') {
                                        _eslbydbenoetigt += 1
                                    }
                                    break;
                                case "GB":
                                case "GBz":
                                    if (_busexit.hinweis == 'E-Bus') {
                                        _egbbenoetigt += 1
                                    } else {
                                        _gbbenoetigt += 1
                                    }
                                    break;
                            }
                        }
                    }
                } else {
                    if (!_busexit.hinweis.match(/NE[1-9]/g) === true || !_busexit.hinweis.match(/NE [1-9]/g) === true) {
                        if (_busexit.anfang <= _busexittypes.timeto &&
                            _busexit.anfang >= _busexittypes.timefrom && !_busexit.gemeldet) {
                            switch(_busexit.fahrzeug) {
                                case "SL":
                                case "SLz":
                                    if (_busexit.hinweis == 'E-Bus') {
                                        _eslmbbenoetigt += 1
                                    } else {
                                        _slbenoetigt += 1
                                    }
                                    break;
                                case "ESL":
                                    if (_busexit.hinweis == 'MB') {
                                        _eslmbbenoetigt += 1
                                    } else if (_busexit.hinweis == 'BYD') {
                                        _eslbydbenoetigt += 1
                                    }
                                    break;
                                case "GB":
                                case "GBz":
                                    if (_busexit.hinweis == 'E-Bus') {
                                        _egbbenoetigt += 1
                                    } else {
                                        _gbbenoetigt += 1
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
            _anzahl[_busexittypes.text] = {SL: _sl, GB: _gb, ESLBYD: _eslbyd, ESLMB: _eslmb, EGB: _egb, 
                SLsperre: _slsperre, GBsperre: _gbsperre, 
                ESLBYDsperre: _eslbydsperre, ESLMBsperre: _eslmbsperre, EGBsperre: _egbsperre, 
                SLbenoetigt: _slbenoetigt, GBbenoetigt: _gbbenoetigt, 
                ESLBYDbenoetigt: _eslbydbenoetigt, ESLMBbenoetigt: _eslmbbenoetigt, EGBbenoetigt: _egbbenoetigt, 
                id: _busexittypes.text }
        }
        return (_anzahl)
    },

    getBusAnzahlAkt:( state, rootGetters, rootState ) => ( busexitdata ) => {        
        let _anzahl = {}
        const akttime = DateTime.now().setLocale("de").toFormat("HH:mm")
        for (const _busexittypes of rootState.settings.busexittypes) {
            if (_busexittypes.timefrom <= akttime && _busexittypes.timeto >= akttime) {
                let _sl = 0
                let _gb = 0
                let _eslmb = 0
                let _eslbyd = 0
                let _egb = 0
                let _slsperre = 0
                let _gbsperre = 0
                let _eslbydsperre = 0
                let _eslmbsperre = 0
                let _egbsperre = 0
                for (const _strang of state.strang) {
                    if (_strang.name === _busexittypes.text &&
                        _strang.istGesperrt === false &&
                        _strang.inVerteilung === true) {
                        switch(_strang.Kategorie) {
                            case "Solobus":
                                _sl += _strang.anzahl
                                break;
                            case "Gelenkbus":
                                _gb += _strang.anzahl
                                break;
                            case "E-Solobus BYD":
                                _eslbyd += _strang.anzahl
                                break;
                            case "E-Solobus Mercedes":
                                _eslmb += _strang.anzahl
                                break;
                            case "E-Gelenkbus":
                                _egb += _strang.anzahl
                                break;
                        }
                    } else if (_strang.name === _busexittypes.text &&
                        _strang.istGesperrt === true &&
                        _strang.inVerteilung === true) {
                        switch(_strang.Kategorie) {
                            case "Solobus":
                                _slsperre += _strang.anzahl
                                break;
                            case "Gelenkbus":
                                _gbsperre += _strang.anzahl
                                break;
                            case "E-Solobus BYD":
                                _eslbydsperre += _strang.anzahl
                                break;
                            case "E-Solobus Mercedes":
                                _eslmbsperre += _strang.anzahl
                                break;
                            case "E-Gelenkbus":
                                _egbsperre += _strang.anzahl
                                break;
                        }
                    }
                }
                let _slbenoetigt = 0
                let _gbbenoetigt = 0
                let _eslbydbenoetigt = 0
                let _eslmbbenoetigt = 0
                let _egbbenoetigt = 0
                for (const _busexit of busexitdata) {
                    if (_busexittypes.text.toUpperCase() === "AUSFAHRT NACHTEXPRESS") {
                        if (!_busexit.hinweis.match(/NE[1-9]/g) === false || !_busexit.hinweis.match(/NE [1-9]/g) === false) {
                            if (_busexit.anfang <= _busexittypes.timeto &&
                                _busexit.anfang >= _busexittypes.timefrom && !_busexit.gemeldet) {
                                switch(_busexit.fahrzeug) {
                                    case "SL":
                                    case "SLz":
                                        if (_busexit.hinweis == 'E-Bus') {
                                            _eslmbbenoetigt += 1
                                        } else {
                                            _slbenoetigt += 1
                                        }
                                        break;
                                    case "ESL":
                                        if (_busexit.hinweis == 'MB') {
                                            _eslmbbenoetigt += 1
                                        } else if (_busexit.hinweis == 'BYD') {
                                            _eslbydbenoetigt += 1
                                        }
                                        break;
                                    case "GB":
                                    case "GBz":
                                        if (_busexit.hinweis == 'E-Bus') {
                                            _egbbenoetigt += 1
                                        } else {
                                            _gbbenoetigt += 1
                                        }
                                        break;
                                }
                            }
                        }
                    } else {
                        if (!_busexit.hinweis.match(/NE[1-9]/g) === true || !_busexit.hinweis.match(/NE [1-9]/g) === true) {
                            if (_busexit.anfang <= _busexittypes.timeto &&
                                _busexit.anfang >= _busexittypes.timefrom && !_busexit.gemeldet) {
                                switch(_busexit.fahrzeug) {
                                    case "SL":
                                    case "SLz":
                                        if (_busexit.hinweis == 'E-Bus') {
                                            _eslmbbenoetigt += 1
                                        } else {
                                            _slbenoetigt += 1
                                        }
                                        break;
                                    case "ESL":
                                        if (_busexit.hinweis == 'MB') {
                                            _eslmbbenoetigt += 1
                                        } else if (_busexit.hinweis == 'BYD') {
                                            _eslbydbenoetigt += 1
                                        }
                                        break;
                                    case "GB":
                                    case "GBz":
                                        if (_busexit.hinweis == 'E-Bus') {
                                            _egbbenoetigt += 1
                                        } else {
                                            _gbbenoetigt += 1
                                        }
                                        break;
                                }
                            }
                        }
                    }
                }
                _anzahl[_busexittypes.text] = {SL: _sl, GB: _gb, ESLBYD: _eslbyd, ESLMB: _eslmb, EGB: _egb, 
                    SLsperre: _slsperre, GBsperre: _gbsperre, 
                    ESLBYDsperre: _eslbydsperre, ESLMBsperre: _eslmbsperre, EGBsperre: _egbsperre, 
                    SLbenoetigt: _slbenoetigt, GBbenoetigt: _gbbenoetigt, 
                    ESLBYDbenoetigt: _eslbydbenoetigt, ESLMBbenoetigt: _eslmbbenoetigt, EGBbenoetigt: _egbbenoetigt, 
                    id: _busexittypes.text }
            }
        }
        return (_anzahl)
    },

    strangNummer(state) {
        return state.strang.reduce((acc, cur) => {
            if (acc.indexOf(cur.strangNummer) < 0) {
                acc.push(cur.strangNummer)
            }
            return acc
        }, []).map(i => parseInt(i))
    },

    busTypInStrang: (state) => (busType, serviceType) => {
        const filteredStrang = state.strang
            .filter(strang => {
                return strang.Kategorie == busType && strang.istGesperrt == false && strang.inVerteilung == true
            });

        switch (serviceType) {
            case 'SBS': return filteredStrang.filter(strang => strang.name == 'SBS')
            case 'Kurzdienst': return filteredStrang.filter(strang => strang.name == 'Kurzdienst')
            case 'Normal': return filteredStrang.filter(strang => strang.name == 'Normal')
            default: return filteredStrang.filter(strang => strang.name == serviceType)
        }
    },

    getStrang: (state) => (strangNummer, strangType) => {
        if (strangType != 'sbs') {
            return state.strang.find(strang => strang.strangNummer == strangNummer && strang.type == strangType)
        } else {
            return state.strang.find(strang => strang.strangNummer == strangNummer)
        }
    },

    getStrangSonderposition: (state) => (busType, nachtexpress=false) => {
        const filteredStrang = state.strang
            .filter(strang => {
                return strang.Kategorie == busType && strang.istGesperrt == false && 
                        strang.isBlocked == false && parseInt(strang.strangNummer) >= 100 &&
                        strang.anzahl < strang.maxBus && strang.nachtexpress == nachtexpress
            });
        return filteredStrang;
    }
}

const actions = {
/*    attachStrangbyType({ state, rootState }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.strang);
                return;
            }
            state.isLoading = true;
            const q = query(collection(db, "strang"));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    //state.strang.splice(0, state.strang.length);
                    state.strang = {};
                    for (const _bt of rootState.settings.busexittypes) {
                        state.strang[_bt.id] = [];
                    }
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            const _doc = doc.data();
                            state.strang[_doc.type].push(_doc);
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        //                        console.log("Data came from " + source);
                    });
                    console.log("Data: ", state.strang);
                    state.isLoading = false;
                    resolve(state.strang);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                });
        });
    },
*/
    attachStrangOnSnapshotoffline({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.strang);
                return;
            }
            state.isLoading = true;

            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "strang"))
            // where("meldezeit", ">=", zeit),
            // where("gemeldet", "==", false),
            // orderBy("meldezeit"));

            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.strang.splice(0, state.strang.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.strang.push(doc.data());
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        //                        console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.strang);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    attachStrangNamenOnSnapshotoffline({ state }) {
        return new Promise((resolve, reject) => {
            if (state.strangNamenFirestoreListener) {
                resolve(state.strangNamen);
                return;
            }
            state.strangNamenIsLoading = true;

            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "strangnamen"))
            // where("meldezeit", ">=", zeit),
            // where("gemeldet", "==", false),
            // orderBy("meldezeit"));

            state.strangNamenFirestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.strangNamen = []
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.strangNamen.push(doc.data());
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        console.log("Data came from " + source);
                    });
                    state.strangNamenIsLoading = false;
                    resolve(state.strangNamen);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachStrangOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
    },

    Strangposleer({ state, rootState, commit, dispatch }, { docid, data, strangNummer, strangPosition}) {
        const docRef = doc(db, "strang", docid);
        //console.log('Strang Leer', docid, data, docRef);
        const _doc = state.strang.find(strang => strang.id == docid);
        dispatch("createNotification", {
            data: {
                content: `Strang ${strangNummer} Position ${strangPosition} wurde von ${rootState.auth.employee.firstname} ${rootState.auth.employee.name} (${rootState.auth.employee.persid}) als leer gemeldet.`,
                createdAt: new Date(),
                ttl: getTimeStamp(DateTime.now().setLocale("de").plus({ days: +14 })),
                destination: "strangverwaltung",
                openedBy: [],
                permissions: ["strangverwaltung"],
                title: `Strang ${strangNummer} Position ${strangPosition} ist leer`,
                viewedBy: [],
            }
        });
    },

    Strangleer({ state, rootState, commit, dispatch }, { docid, data, strangNummer = '1' }) {
        const docRef = doc(db, "strang", docid);
        //console.log('Strang Leer', docid, data, docRef);
        const _doc = state.strang.find(strang => strang.id == docid);
        for (let _pos = 1; _pos <= _doc.maxBus; _pos++) {
            let _kd = _doc.kurzdienst
            _doc.position[_pos] = {
                anzahl: 0,
                kurzdienst: _kd,
            };
        }
        data.anzahl = 0;
        data.position = _doc.position;
        dispatch("createNotification", {
            data: {
                content: `Strang ${strangNummer} wurde von ${rootState.auth.employee.firstname} ${rootState.auth.employee.name} (${rootState.auth.employee.persid}) gesperrt.`,
                createdAt: new Date(),
                ttl: getTimeStamp(DateTime.now().setLocale("de").plus({ days: +14 })),
                destination: "strangverwaltung",
                openedBy: [],
                permissions: ["strangverwaltung"],
                title: `Strang ${strangNummer} wurde gesperrt`,
                viewedBy: [],
            }
        });
        updateDoc(docRef, data).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    updateStrang({ rootState, commit, dispatch }, { docid, 
                                data, strangNummer = '', 
                                name =  `${rootState.auth.employee.firstname} ${rootState.auth.employee.name} (${rootState.auth.employee.persid})`,
                                position = '',
                            }) {
        console.log('UPDATE_STRANG', docid, data);
        const docRef = doc(db, "strang", docid);

        if (data.istGesperrt) {
            dispatch("createNotification", {
                data: {
                    content: `Strang ${strangNummer} ${data.nameobj.text} wurde von ${name} gesperrt. ` + JSON.stringify(data),
                    createdAt: new Date(),
                    ttl: getTimeStamp(DateTime.now().setLocale("de").plus({ days: +14 })),
                    destination: "strangverwaltung",
                    openedBy: [],
                    permissions: ["strangverwaltung"],
                    title: `Strang ${strangNummer} ${data.nameobj.text} wurde gesperrt `,
                    viewedBy: [],
                }
            });
        } else if (!data.inVerteilung) {
            dispatch("createNotification", {
                data: {
                    content: `Strang ${strangNummer} ${data.nameobj.text} wurde von ${name} aus der Verteilung entfernt. ` + JSON.stringify(data),
                    createdAt: new Date(),
                    ttl: getTimeStamp(DateTime.now().setLocale("de").plus({ days: +14 })),
                    destination: "strangverwaltung",
                    openedBy: [],
                    permissions: ["strangverwaltung"],
                    title: `Strang ${strangNummer} ${data.nameobj.text} wurde aus der Verteilung entfernt. `,
                    viewedBy: [],
                }
            });
        } else if (position != '') {
            dispatch("createNotification", {
                data: {
                    content: `Strang ${strangNummer} ${data.nameobj.text} wurde von ${name} an Position ${position} geändert. `+ JSON.stringify(data),
                    createdAt: new Date(),
                    ttl: getTimeStamp(DateTime.now().setLocale("de").plus({ days: +14 })),
                    destination: "strangverwaltung",
                    openedBy: [],
                    permissions: ["strangverwaltung"],
                    title: `Strang ${strangNummer} ${data.nameobj.text} wurde geändert `,
                    viewedBy: [],
                }
            });
        } else {
            dispatch("createNotification", {
                data: {
                    content: `Strang ${strangNummer} ${data.nameobj.text} wurde von ${name} geändert. `+ JSON.stringify(data),
                    createdAt: new Date(),
                    ttl: getTimeStamp(DateTime.now().setLocale("de").plus({ days: +14 })),
                    destination: "strangverwaltung",
                    openedBy: [],
                    permissions: ["strangverwaltung"],
                    title: `Strang ${strangNummer} ${data.nameobj.text} wurde geändert `,
                    viewedBy: [],
                }
            });
        }

        updateDoc(docRef, data).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    addStrang({ commit }, { docid, data }) {
        console.log('INSERT', docid, data);
        const docRef = doc(db, "strang", docid);
        setDoc(docRef, data).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    deleteStrang({ commit }, { docid }) {
        console.log('DELETE', docid)
        const docRef = doc(db, "strang", docid);
        deleteDoc(docRef).then(() => {
            commit('showSnackbar', { message: 'Eintrag gelöscht', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

/*    addStrangName({ commit }, { docid, data }) {
        console.log('INSERT', docid, data);
        const docRef = doc(db, "strangnamen", docid);
        setDoc(docRef, data).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },
*/
    // getBusAnzahlProTypeInStrang({ state, dispatch }, { busType }) {
    //     const date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    //     dispatch("attachBusExitOnSnapshotoffline", { date })
    //         .then(() => {
    //             dispatch('attachStrangOnSnapshotoffline')
    //             dispatch("getPersonalTask");
    //             console.log('RECEIVEDBUSTYPE', busType)
    //         }).then(() => {
    //             state.busQuantity = state.strang
    //                 .filter(strang => {
    //                     return strang.Kategorie == busType
    //                 })
    //                 .reduce((acc, cur) => acc + cur.anzahl, 0)
    //             console.log(state.busQuantity)
    //         })


    // return state
    // .filter(strang => {
    //     console.log(strang)
    //     return strang
    // })
    // .reduce((acc, cur) => acc + cur.anzahl, 0)
    // }
}

export default { state, getters, actions }
