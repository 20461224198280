<template>
  <form>
    <v-container>
      <editbusexittime 
        :active="dialogs.addbusexittime" 
        :busexittime="$store.state.settings.settings.busexittime"
        text="Ändern der Einfahrts Zeit"
      ></editbusexittime>
      <editbusexittime 
        :active="dialogs.addchangebusexittime" 
        :busexittime="$store.state.settings.settings.changebusexit"
        text="Ändern der Zeit des Ausfahrtswechsel"
      ></editbusexittime>
      <v-card>
        <v-card-title>
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Strangverwaltung
        </v-card-title>
        <v-card-text>
          <template>
            <template v-if="$store.state.settings.busexittypesisOnline == true">
              <v-expansion-panels multiple :loading="$store.state.strang.isLoading">
                <v-expansion-panel v-for="(_bt, btindex) in $store.state.settings.busexittypes" :key="btindex">
                  <v-expansion-panel-header>
                    <v-card>
                      <v-card-title>
                        <v-icon>mdi-bus-clock</v-icon>
                        &nbsp; {{ _bt.text }} von {{ _bt.timefrom }} bis {{ _bt.timeto }}
                      </v-card-title>
                    </v-card>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="strangheaders"
                      fixed-header
                      :items="filterStrang(_bt.id)"
                      :loading="$store.state.strang.isLoading"
                      group-by="Kategorie"
                      sort-by="strangNummer"
                      class="elevation-1"
                      :items-per-page="itemsPerPage"
                      hide-default-footer
                      height="calc(100vh - 245px)"
                    >
                      <template v-slot:top>
                        <!-- <v-toolbar flat> -->
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="55em">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              Anlegen
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.strangNummer"
                                      label="Strangnummer"
                                      type="number"
                                      min="0"
                                      :disabled="editedIndex >= 0"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="8">
                                    <v-text-field
                                      v-model="editedItem.strangText"
                                      label="Strang"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="5">
                                    <v-text-field
                                      v-model="editedItem.maxBus"
                                      label="Max Busse"
                                      type="number"
                                      min="0"
                                      :disabled="maxBusdisabled"
                                      @input="changeMaxAnzahl(editedItem)"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="5">
                                    <v-text-field
                                      v-model="editedItem.anzahl"
                                      label="Anzahl"
                                      type="number"
                                      min="0"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="2">
                                    <p>Prio.</p>
                                    <v-switch
                                      :input-value="editedItem.priorisiert"
                                      @change="toggleeditedItemPrioAction(editedItem)"
                                    >
                                    </v-switch>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="5">
                                    <v-autocomplete
                                      label="Kategorie"
                                      :items="$store.state.strang.busseKategorie"
                                      v-model="editedItem.Kategorie"
                                    >
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="5">
                                    <v-autocomplete
                                      :items="$store.state.settings.busexittypes"
                                      :loading="$store.state.settings.busexittypesisLoading"
                                      item-text='text'
                                      item-value='id'
                                      v-model="editedItem.nameobj" 
                                      label="Typ" 
                                      return-object
                                      :disabled="editedIndex >= 0"
                                    >
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="2">
                                    <p>Kurzd.</p>
                                    <v-switch
                                      :input-value="editedItem.kurzdienst"
                                      @change="toggleeditedItemKurzdienstAction(editedItem)"
                                      :disabled="_bt.id != 'frueh'"
                                    >
                                    </v-switch>
                                  </v-col>
                                </v-row>
                                <v-template class="postemplate">
                                <div v-for="(pos, indexpos) in editedItem.position" :key="indexpos"
                                    :class="classNameByModulo(indexpos)">
                                  <v-card>
                                    <v-card-title>
                                      Position {{ indexpos }}
                                    </v-card-title>
                                    <v-card-text>
                                      <template>
                                        <v-row>
                                          <v-col cols="12" sm="6" md="7">
                                            <v-text-field
                                              v-model="editedItem.position[indexpos].anzahl"
                                              label="Anzahl"
                                              type="number"
                                              min="0"
                                              :max="posAnzahlMax"
                                              @input="changePosAnzahl(editedItem, indexpos)"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="12" sm="6" md="5">
                                            <p>Kurzd.</p>
                                            <v-switch
                                              :input-value="editedItem.position[indexpos].kurzdienst"
                                              @change="toggleeditedItemPosKurzdienstAction(editedItem, indexpos)"
                                              :disabled="_bt.id != 'frueh'"
                                            >
                                            </v-switch>
                                          </v-col>
                                        </v-row>
                                      </template>
                                    </v-card-text>
                                  </v-card>
                                </div>
                                </v-template>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="close">
                                Abbrechen
                              </v-btn>
                              <v-btn color="blue darken-1" text @click="save">
                                Speichern
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="750px">
                          <v-card>
                            <v-card-title class="text-h5">
                              Möchten Sie dieses Element wirklich löschen?
                            </v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDelete">
                                Abbrechen
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItemConfirm"
                              >
                                OK
                              </v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <!-- </v-toolbar> -->
                      </template>
                      <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                        <th :colspan="strangheaders.length">
                          <v-icon @click="toggle">
                            {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                          </v-icon>
                          {{ items[0].Kategorie }}
                        </th>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" :disabled="item.isBlocked" @click="editItem(item)">
                          mdi-pencil
                        </v-icon>
                        <v-icon small :disabled="item.isBlocked" @click="deleteItem(item)"> mdi-delete </v-icon>
                      </template>
                      <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize"> Reset </v-btn>
                      </template>
                      <template v-slot:[`item.priorisiert`]="{ item }">
                        <v-tooltip top >
                          <template  v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" style="width:min-content;">
                              <v-switch
                                :input-value="item.priorisiert"
                                @change="togglePrioAction(item)"
                                :disabled="item.isBlocked"
                              ></v-switch>
                            </div>
                          </template>
                          Strang priorisiert
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.kurzdienst`]="{ item }">
                        <v-tooltip top >
                          <template  v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" style="width:min-content;">
                              <v-switch
                                :input-value="item.kurzdienst"
                                @change="toggleKurzdienstAction(item)"
                                :disabled="_bt.id != 'frueh' || item.isBlocked"
                              ></v-switch>
                            </div>
                          </template>
                          Kurzdienst
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.nachtexpress`]="{ item }">
                        <v-tooltip top >
                          <template  v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" style="width:min-content;">
                              <v-switch
                                :input-value="item.nachtexpress"
                                @change="toggleNachtexpressAction(item)"
                                :disabled="item.isBlocked"
                              ></v-switch>
                            </div>
                          </template>
                          Nachtexpress
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.istGesperrt`]="{ item }">
                        <v-tooltip top >
                          <template  v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" style="width:min-content;">
                              <v-switch
                                :input-value="item.istGesperrt"
                                @change="toggleLockAction(item)"
                                :disabled="item.isBlocked"
                              >
                              </v-switch>
                            </div>
                          </template>
                          Strang sperren
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.inVerteilung`]="{ item }">
                        <v-tooltip top >
                          <template  v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" style="width:min-content;">
                              <v-switch
                                :input-value="item.inVerteilung"
                                @change="toggleInVerteilungAction(item)"
                                :disabled="!hasPermission && item.isBlocked"
                              >
                              </v-switch>
                            </div>
                          </template>
                          Strang in Verteilung
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </template>
        </v-card-text>
      </v-card>
      <v-speed-dial
        v-if="$store.getters.hasCustomPermission('securityincidentedit')"
        v-model="fab"
        fixed
        right
        bottom
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="primary"
            dark
            fab
            value="SpeedDail"
            v-show="!isLoading"
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="dialogs.addbusexittime++"
              elevation="2"
              fab
              small
              color="success"
              v-show="!isLoading"
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-clock-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Ändern der Einfahrts Zeit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="dialogs.addchangebusexittime++"
              elevation="2"
              fab
              small
              color="success"
              v-show="!isLoading"
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-clock-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Ändern der Zeit des Ausfahrtswechsel</span>
        </v-tooltip>
      </v-speed-dial>
    </v-container>
  </form>
</template>

<script>
import { DateTime } from "luxon";
import editbusexittime from "@/components/dialog/editbusexittime";
const timezone = 'Europe/Berlin';
export default {
  data() {
    return {
      doc: "",
      itemsPerPage: 100,
      strangheaders: [
        {
          text: "Strangnummer",
          align: "start",
          sortable: false,
          value: "strangNummer",
        },
        { text: "Text", value: "strangText", sortable: false },
        { text: "Max Busse", value: "maxBus", sortable: false },
        { text: "Aktuelle Anzahl", value: "anzahl" },
        { text: "Prio", value: "priorisiert", sortable: false },
        { text: "Kurzdienst", value: "kurzdienst", sortable: false },
        { text: "NE", value: "nachtexpress", sortable: false },
        { text: "Gesperrt", value: "istGesperrt", sortable: false },
        {
          text: "In Verteilung berücksichtigen",
          value: "inVerteilung",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false },
      ],
      reloadtimer: "",
      sliderValue: 0,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        strangNummer: "0",
        strangText: "",
        maxBus: 0,
        anzahl: 0,
        Kategorie: "Solobus",
        name: "",
        type: "",
        nameobj: {},
        priorisiert: false,
        istGesperrt: false,
        isBlocked: false,
        inVerteilung: true,
        kurzdienst: false,
        nachtexpress: false,
      },
      defaultItem: {
        strangNummer: "0",
        strangText: "",
        maxBus: 0,
        anzahl: 0,
        Kategorie: "Solobus",
        name: "",
        priorisiert: false,
        istGesperrt: false,
        isBlocked: false,
        inVerteilung: true,
        kurzdienst: false,
        nachtexpress: false,
      },
      selectedName: "",
      customStrangName: "",
      dialogs: {
          addbusexittime: 0,
          addchangebusexittime: 0,
        },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neu" : "Strang bearbeiten";
    },
    currentKategorie() {
      return this.$store.getters.strangKategorie.findIndex(
        (kategorie) => kategorie == this.editedItem.Kategorie
      );
    },
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    hasPermission() {
      return this.$store.getters.hasPermission({
        type: "customUserPermission",
        right: "strangverwaltung_sperrung_verarbeitung",
      });
    },
    posAnzahlMax() {
      if (this.editedItem.strangNummer < 300) {
        return 1
      } else {
        return this.editedItem.maxBus
      }
    },
    maxBusdisabled() {
      let _ret = this.$store.getters.hasPermission({
        type: "customUserPermission",
        right: "strangverwaltung_maxbus_edit",
      });
      _ret = !_ret; //invertieren für Disabled
//      console.log("DISABLED: ", _ret)
      return _ret
    },
  },

  watch: {
    // "$store.state.auth.isAuthenticating": {
    //   handler: function (isAuthenticating) {
    //     if (isAuthenticating) {
    //       this.$store.dispatch("attachStrangOnSnapshotoffline");
    //     }
    //   },
    //   immediate: true,
    // },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    classNameByModulo: function (index) {
      return index % 2 == (0 || 1) ? 'poscardleft' : 'poscardright';
    },
    filterStrang(type) {
      const _data = this.$store.state.strang.strang.filter((data) => {
        let _type = type;
        return ( data.type == _type );
      });
//      console.log("FILTER: ", _data);
      return _data;
    },

    editItem(item) {
      this.editedIndex = this.$store.state.strang.strang.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.$store.state.strang.strang.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("deleteStrang", {
        docid: this.editedItem.strangNummer+'_'+this.editedItem.nameobj.id,
      });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changePosAnzahl(_editedItem, indexpos) {
      if (this.editedItem.position[indexpos].anzahl == 0) {
        this.editedItem.anzahl -= 1
      } else {
        this.editedItem.anzahl += parseInt(_editedItem.position[indexpos].anzahl)
      }
    },

    changeMaxAnzahl(_editedItem) {
      const _positions = _editedItem?.position || {}
      const _len = Object.keys(_editedItem?.position || {}).length
      if (_editedItem.maxBus != _len) {
        if (_editedItem.maxBus > _len) {
          _editedItem.position = {}
          _editedItem.anzahl = 0
          for (let _pos = 1; _pos <= _editedItem.maxBus; _pos++) {
            let _kd = _positions?.[_pos]?.kurzdienst || false;
            if (_editedItem.kurzdienst) {
              _kd = true;
            }
            let _anz = parseInt(_positions?.[_pos]?.anzahl) || 0
            _editedItem.anzahl += _anz
            _editedItem.position[_pos] = {
              anzahl: _anz,
              kurzdienst: _kd,
            };
          }
        }
        if (_editedItem.maxBus < _len) {
          _editedItem.position = {}
          _editedItem.anzahl = 0
          for (let _pos = 1; _pos <= _editedItem.maxBus; _pos++) {
            let _kd = _positions?.[_pos]?.kurzdienst || false;
            if (_editedItem.kurzdienst) {
              _kd = true;
            }
            _editedItem.anzahl += parseInt(_positions[_pos].anzahl)
            _editedItem.position[_pos] = {
              anzahl: _positions[_pos].anzahl,
              kurzdienst: _kd,
            };
          }
        }
      }
    },

    save() {
      if (this.editedItem.maxBus == 0) {
        this.$store.commit("showSnackbar", {
          message: "Bitte geben Sie die Anzahl der Busse ein.",
          status: "error",
        });
        return;
      }
      let posanzahl = 0;
      for (let _pos = 1; _pos <= Object.keys(this.editedItem?.position || {}).length; _pos++) {
        posanzahl += parseInt(this.editedItem.position[_pos].anzahl)
      }
      if (posanzahl != this.editedItem.anzahl) {
        this.$store.commit("showSnackbar", {
          message: `Die Anzahl der Positionen ${posanzahl} stimmt nicht mit der gesamt Anzahl ${this.editedItem.anzahl} überein.`,
          status: "error",
        });
        return;
      }
      if (this.editedIndex > -1) {
//        console.log("UPDATE", this.editedItem);
/*        let _lastdate = DateTime.now().setZone(timezone).setLocale('de').toFormat("yyyy-MM-dd HH:mm:ss:SSS");
        if (parseInt(this.editedItem.strangNummer) >= 50) {
          if (parseInt(this.editedItem.strangNummer) === 50) {
            _lastdate = DateTime.now().setZone(timezone).setLocale('de').toFormat("1980-01-01 00:00:00:000");
          } else if (parseInt(this.editedItem.strangNummer) === 51) {
            _lastdate = DateTime.now().setZone(timezone).setLocale('de').toFormat("1981-01-01 00:00:00:000");
          } else {
            _lastdate = DateTime.now().setZone(timezone).setLocale('de').toFormat("yyyy-MM-dd 00:00:00:000")
          }
        }*/
        this.$store.dispatch("updateStrang", {
          docid: this.editedItem.strangNummer+'_'+this.editedItem.nameobj.id,
          data: {
            ...this.editedItem,
            name: this.editedItem.nameobj.text,
            type: this.editedItem.nameobj.id,
            anzahl: +this.editedItem.anzahl,
            maxBus: +this.editedItem.maxBus,
            isBlocked: false,
//            lastdate: _lastdate,
          },
          strangNummer: this.editedItem.strangNummer.toString(),
          name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
        });
      } else {        
//        console.log("INSERT", this.editedItem);
/*        if (
          this.$store.getters.strangNummer.indexOf(
            parseInt(this.editedItem.strangNummer)
          ) > -1
        ) {
          this.$store.commit("showSnackbar", {
            message: "Strangnummer wurde vergeben.",
            status: "error",
          });
          return;
        }*/
        const _strang = this.$store.getters.getStrang(this.editedItem.strangNummer, this.editedItem.nameobj.id);
        if (typeof(_strang) != 'undefined') {
          this.$store.commit("showSnackbar", {
            message: "Strangnummer "+this.editedItem.strangNummer+" in der "+this.editedItem.nameobj.text+" ist schon vergeben.",
            status: "error",
          });
          return;
        }

        this.$store.dispatch("addStrang", {
          docid: this.editedItem.strangNummer+'_'+this.editedItem.nameobj.id,
          data: {
            ...this.editedItem,
            name: this.editedItem.nameobj.text,
            type: this.editedItem.nameobj.id,
            anzahl: +this.editedItem.anzahl,
            maxBus: +this.editedItem.maxBus,
            lastdate: DateTime.now().setZone(timezone).setLocale('de').toFormat("yyyy-MM-dd HH:mm:ss:SSS"),
          },
        });
      }
      this.customStrangName = "";
      this.close();
    },

    toggleeditedItemKurzdienstAction(item) {
      item.kurzdienst = !item.kurzdienst;
      const _positions = item.position
      for (let _pos = 1; _pos <= item.maxBus; _pos++) {
        let _kd = item.kurzdienst
        item.position[_pos] = {
          anzahl: _positions[_pos].anzahl,
          kurzdienst: _kd,
        };
      }
    },

    toggleeditedItemPosKurzdienstAction(item, pos) {
      item.position[pos].kurzdienst = !item.position[pos].kurzdienst;
    },

    toggleeditedItemPrioAction(item) {
      item.priorisiert = !item.priorisiert;
    },

    toggleLockAction(item) {
      item.istGesperrt = !item.istGesperrt;
      this.$store.dispatch("updateStrang", {
        docid: item.strangNummer.toString()+"_"+item.type,
        data: item,
        strangNummer: item.strangNummer.toString(),
        name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
      });
    },

    toggleInVerteilungAction(item) {
      item.inVerteilung = !item.inVerteilung;
      this.$store.dispatch("updateStrang", {
        docid: item.strangNummer.toString()+"_"+item.type,
        data: item,
        strangNummer: item.strangNummer.toString(),
        name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
//        data: { inVerteilung: !item.inVerteilung },
      });
    },

    togglePrioAction(item) {
      item.priorisiert = !item.priorisiert;
      this.$store.dispatch("updateStrang", {
        docid: item.strangNummer.toString()+"_"+item.type,
        data: item,
        strangNummer: item.strangNummer.toString(),
        name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
      });
    },

    toggleKurzdienstAction(item) {
      item.kurzdienst = !item.kurzdienst;
      const _positions = item.position
      for (let _pos = 1; _pos <= item.maxBus; _pos++) {
        let _kd = item.kurzdienst
        item.position[_pos] = {
          anzahl: _positions[_pos].anzahl,
          kurzdienst: _kd,
        };
      }
      this.$store.dispatch("updateStrang", {
        docid: item.strangNummer.toString()+"_"+item.type,
        data: item,
        strangNummer: item.strangNummer.toString(),
        name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
//        data: { kurzdienst: item.kurzdienst, position: item.position },
      });
    },

    toggleNachtexpressAction(item) {
      item.nachtexpress = !item.nachtexpress;
      this.$store.dispatch("updateStrang", {
        docid: item.strangNummer.toString()+"_"+item.type,
        data: item,
        strangNummer: item.strangNummer.toString(),
        name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
      });
    },
  },

  beforeDestroy() {
    //this.$store.dispatch("detachStrangOnSnapshot");
    this.$store.dispatch("detachhbusexittypes");
  },

  created() {
    this.$store.dispatch("attachStrangNamenOnSnapshotoffline")
    this.$store.dispatch("attachbusexittypes")
    /*this.$store.dispatch("attachbusexittypes").then(() => {
      this.$store.dispatch("attachSettingsOnSnapshotoffline");
      //this.$store.dispatch("attachStrangOnSnapshotoffline");
      this.$store.dispatch("attachStrangNamenOnSnapshotoffline");
    });*/
  },

  mounted () {
  },

  components: {
    editbusexittime,
  }
};
</script>

<style scoped>
.postemplate {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.poscardleft {
  grid-column: 1;
}
.poscardright {
  grid-column: 2;
}
</style>
